import { UIButton, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import { withRouter } from 'react-router-dom'
import messages from '../../consts/messages'

const NotFound = (props) => {
  const { muiTheme } = window.brandProps
  const { history } = props

  const clickHandler = () => history.push('/')

  return (
    <UILayout
      data-test="not-found-page"
      column
      center
      j-flex-center
      bgColor="white"
      margin="30px 0 30px 0"
      padding="20px"
      height="550px"
    >
      <UILayout column center margin="20px 0">
        <UIText
          color={(muiTheme.ids.notFoundTitleStyles && muiTheme.ids.notFoundTitleStyles.color) || '#4266bd'}
          size="30px"
          font={muiTheme.secondFontFamily}
          translate={messages.pageNotFoundTitle}
        />
        <UIText
          font={muiTheme.secondFontFamily}
          color="#000000"
          opacity="0.7"
          size="18px"
          translate={messages.pageNotFoundSubtitle}
        />
      </UILayout>
      <UIButton
        width="240px"
        height="50px"
        margin="0 0 50px 0"
        onClick={clickHandler}
        background={muiTheme.ids.buttonStyles && muiTheme.ids.buttonStyles.background}
      >
        <UILayout j-flex-center center height="100%">
          <UIText font={muiTheme.secondFontFamily} size="20px" translate={messages.pageNotFoundButton} />
        </UILayout>
      </UIButton>
    </UILayout>
  )
}

export default withRouter(NotFound)
